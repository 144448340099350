import {registerLocaleData} from "@angular/common";
import {provideHttpClient} from "@angular/common/http";
import localeDe from "@angular/common/locales/de";
import {ApplicationConfig, LOCALE_ID, provideZoneChangeDetection} from "@angular/core";
import {provideClientHydration} from "@angular/platform-browser";
import {provideAnimations} from "@angular/platform-browser/animations";
import {provideRouter} from "@angular/router";
import {environment} from "@casavina/utils/environments/environment";
import {provideStore} from "@ngrx/store";
import {ConfirmationService, MessageService} from "primeng/api";
import {APP_ROUTES} from "./routes";

registerLocaleData(localeDe);
export const appConfig: ApplicationConfig = {
	providers: [
		provideZoneChangeDetection({eventCoalescing: true}),
		provideRouter(APP_ROUTES),
		provideHttpClient(),
		provideAnimations(),
		provideStore(),
		provideClientHydration(),
		environment.providers,
		{provide: LOCALE_ID, useValue: "de"},
		MessageService,
		ConfirmationService
	]
};
