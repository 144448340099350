import {ChangeDetectionStrategy, Component, Injectable, OnInit} from "@angular/core";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {RouterLink, RouterOutlet} from "@angular/router";
import {NavbarItem} from "@casavina/ui/navbar/models/navbar-item";
import {NavbarComponent} from "@casavina/ui/navbar/navbar.component";
import {NavbarEndDirective} from "@casavina/ui/navbar/utils/navbar-end.directive";
import deLocale from "@casavina/utils/i18n/primeng-locale.de";
import {PrimeNGConfig} from "primeng/api";
import {ConfirmDialogModule} from "primeng/confirmdialog";
import {ToastModule} from "primeng/toast";
import {FooterComponent} from "./footer/footer.component";

@Component({
	standalone: true,
	selector: "app-root",
	templateUrl: "./app.component.html",
	styleUrls: ["./app.component.scss"],
	imports: [
		RouterOutlet,
		FooterComponent,
		NavbarComponent,
		NavbarEndDirective,
		ConfirmDialogModule,
		ToastModule,
		MatButtonModule,
		MatIconModule,
		RouterLink
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
@Injectable()
export class AppComponent implements OnInit {
	title = "Casa Vina - Startseite";
	public navbarItems: NavbarItem[] = [
		{
			label: "Startseite",
			routerLink: "/"
		},
		{
			label: "Menü",
			routerLink: "/menu"
		},
		{
			label: "Öffnungszeiten",
			routerLink: "/location"
		}
	];

	constructor(private primeConfig: PrimeNGConfig) {
	}

	ngOnInit() {
		this.primeConfig.setTranslation(deLocale);
	}
}
