<div class = "mat-typography hyphens-auto text-wrap md:text-balance" lang="de" >
	<div class = "md:container md:mx-auto mt-1 px-4 flex flex-col space-x-4 gap-5 min-h-screen" >
		<casavina-navbar [logo] = "'assets/logo.png'" [navbarItems] = "navbarItems" >
			<ng-template csNavbarEnd >
				<button color = "primary" mat-raised-button routerLink = "/reservation">
					Reservierung
				</button >
			</ng-template >
		</casavina-navbar >
		<div class = "content" >
			<router-outlet />
		</div >
		<casavina-footer class = "mt-auto" />
	</div >
	<p-toast [breakpoints] = "{ '599.98px': { width: '100%', right: '0', left: '0' } }"
	         [life] = "5000"
	         [preventOpenDuplicates] = "true" />
	<p-confirmDialog rejectButtonStyleClass = "p-button-text p-button-plain" />
</div >
