<div>
	<div >
		<h1 class = "social" >In Verbindung bleiben</h1 >
		<div class = "social-icons" >
			<a class = "social-icon"
			   href = "https://www.facebook.com/profile.php?id=100092988253022"
			   target = "_blank" >
				<img alt = "facebook logo" height = "57.5" ngSrc = "assets/square-facebook.svg" width = "50" />
			</a >
		</div >
	</div >
</div >
<div class = "footer py-3" >
	<div class = "footer-content" >
		<div class = "logo" >
			<p >
				&copy; 2023 Casa Vina ◎ Karlsruher Straße 143 ◎ 01189 Dresden ◎
				<a href = "tel:+493514011114" >+49 351 4011114</a >
			</p >
		</div >
		<div class = "links mat-typography" >
			<a *ngFor = "let route of footerRoutes" [routerLink] = "route.route" mat-button >
				{{ route.title }}
			</a >
		</div >
	</div >
</div >
