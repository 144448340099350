import {NgForOf, NgOptimizedImage} from "@angular/common";
import {ChangeDetectionStrategy, Component, OnInit} from "@angular/core";
import {MatButtonModule} from "@angular/material/button";
import {RouterLink} from "@angular/router";

@Component({
	standalone: true,
	selector: "casavina-footer",
	templateUrl: "./footer.component.html",
	imports: [RouterLink, MatButtonModule, NgForOf, NgOptimizedImage],
	styleUrls: ["./footer.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent implements OnInit {
	public footerRoutes: any[] = [
		{
			title: "Impressum",
			route: "/pages/impressum"
		},
		{
			title: "Datenschutz",
			route: "/pages/datenschutz"
		},
		{
			title: "Einwilligungsverwaltung",
			route: "/pages/einwilligungsverwaltung"
		},
		{
			title: "AGB",
			route: "/pages/agb"
		}
	];

	constructor() {
	}

	ngOnInit(): void {
	}
}
