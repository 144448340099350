import {provideState} from "@ngrx/store";
import {AllergenEffects, allergenFeature} from "./Allergen";
import {CategoryEffects, categoryFeature} from "./Category";
import {IngredientEffects, ingredientFeature} from "./Ingredient";
import {MenuEffects, menuFeature} from "./Menu";

export const CoreReducers = [
	provideState(categoryFeature),
	provideState(menuFeature),
	provideState(allergenFeature),
	provideState(ingredientFeature)
];

export const CoreEffects = [
	CategoryEffects,
	MenuEffects,
	AllergenEffects,
	IngredientEffects
];
