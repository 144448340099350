import {Routes} from "@angular/router";
import {CoreEffects, CoreReducers} from "@casavina/data/store/Core/core.store";
import {EventEffects, eventFeature} from "@casavina/data/store/Event";
import {OfferEffects, offerFeature} from "@casavina/data/store/Offer";
import {ReservationEffects, reservationFeature} from "@casavina/data/store/Reservation";
import {provideEffects} from "@ngrx/effects";
import {provideState} from "@ngrx/store";

export const APP_ROUTES: Routes = [
	{
		path: "",
		title: "Casa Vina - Startseite",
		loadComponent: () =>
			import("./main-page/main-page.component").then(
				(c) => c.MainPageComponent
			),
		providers: [
			provideState(eventFeature),
			provideEffects(EventEffects),
			provideState(offerFeature),
			provideEffects(OfferEffects)
		]
	},
	{
		path: "menu",
		loadChildren: () => import("./menu/menu-routes"),
		providers: [CoreReducers, provideEffects(CoreEffects)]
	},
	{
		path: "location",
		title: "Casa Vina - Kontakt",
		loadComponent: () =>
			import("./location/location.component").then((c) => c.LocationComponent)
	},
	{
		path: "reservation",
		title: "Casa Vina - Reservierung",
		loadComponent: () =>
			import("./reservation/reservation.component").then(
				(m) => m.ReservationComponent
			),
		providers: [
			provideState(reservationFeature),
			provideEffects(ReservationEffects)
		]
	},
	{
		path: "pages",
		loadChildren: () => import("./footer-pages/footer-pages-routes")
	},
	{
		path: "**",
		title: "Casa Vina - Page Not Found",
		loadComponent: () =>
			import("@casavina/ui/page-not-found/page-not-found.component").then(
				(c) => c.PageNotFoundComponent
			)
	}
];
